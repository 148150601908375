<template>
  <div id="orderList">
    <ul>
      <li v-for="order in orders" :key="order.id">
        <span>ID：{{ order.id }}</span>
        <span>类型：{{ order.type }}</span>
        <span>内容：{{ order.content }}</span>
      </li>
    </ul>
  </div>
</template>
<script>

import {checkBind, setEnterPath} from "../../utils/base";

export default {
  data() {
    return {
      orders: [
        {
          id: '1',
          type: '图片',
          content: 'text'
        },
        {
          id: '2',
          type: '视频',
          content: 'text'
        },
        {
          id: '3',
          type: '文件',
          content: 'text'
        }
      ]
    }
  },
  mounted() {

    // setEnterPath('/material/index');

    // 判断绑定
    if (!checkBind(this.$router)) {
      return false;
    }


  }
}
</script>
